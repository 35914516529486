<template lang="pug">
v-bottom-sheet(
  v-model="isActive"
  content-class="reset-password-dialog"
  transition="dialog-bottom-transition"
  width="100%"
)
  .sh-1
    v-toolbar.px-2(flat)
      v-spacer
      v-toolbar-title.t-h3-mobile.mb-0 Восстановление пароля
      v-spacer
      ui-btn(icon plain @click="cancel")
        ui-icon(name="close")
  .px-4.my-5
    v-text-field(v-model="email" label="Электронная почта" hide-details="auto")
    ui-btn.mt-8(
      large
      primary
      width="100%"
      @click="requestPasswordReset"
    ) Отправить ссылку
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';
  import { formErrors } from '@/uikit/mixins/formErrors';
  import { service as API } from '@/services/auth';
  import { handleError } from '@/libs/handle-error';

  export default {
    components: { UiBtn, UiIcon },
    mixins: [formErrors],

    props: {
      email: { type: String, default: undefined },
    },

    data() {
      return {
        isActive: false,
        requestEmail: this.email,
      };
    },

    methods: {
      open(options) {
        options = options || {};
        this.options = { ...this.options, ...options };
        this.isActive = true;

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },

      confirm() {
        this.resolve(true);
        this.isActive = false;
      },

      cancel() {
        this.resolve(false);
        this.isActive = false;
      },

      async requestPasswordReset() {
        try {
          this.clearErrors();
          const ok = await API.requestPasswordReset(this.requestEmail);
          if (ok) {
            this.$notify.info(
              'В течение нескольких минут вы получите письмо с инструкцией по восстановлению пароля'
            );
            this.confirm();
          }
        } catch (error) {
          handleError(error);
          if (error.data && error.data.data) {
            this.$notify.error(error.data.message);
            this.extractResponseErrors(error.data, 'email');
          }
        }
      },
    },
  };
</script>

<style lang="scss">
  .reset-password-dialog {
    background-color: c('white', 'light');
  }
</style>
