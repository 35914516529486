<template lang="pug">
.code-verification-form
  v-text-field.mt-4(
    v-model="code"
    label="Код из смс"
    autofocus
    hide-details="auto"
    :error-messages="errors.code"
    @keyup.enter="$emit('submit')"
  )
    template(#append)
      ui-countdown-timer(ref="countdownTimer" v-model="codeTimeLeft" :duration="timerDuration")
        .t-small.c-text {{ codeTimeLeft | duration }}

  template(v-if="codeTimeLeft == 0")
    .t-small.c-text.mt-4 Не пришла СМС с кодом?&nbsp;
    .t-caption-middle.c-primary(@click="sendCode") Отправить повторно
</template>

<script>
  import { UiBtn, UiIcon, UiCountdownTimer } from '@/uikit';
  const SMS_COUNTDOWN_DURATION = 60; // сек

  export default {
    components: { UiBtn, UiIcon, UiCountdownTimer },

    props: {
      resendDelay: { type: Number, default: SMS_COUNTDOWN_DURATION },
      phone: { type: String, default: undefined },
      value: { type: String, default: undefined },
      errors: { type: Object, default: () => {} },
    },

    data() {
      return {
        codeTimeLeft: undefined,
        timerDuration: this.resendDelay,
      };
    },

    computed: {
      code: {
        set(value) {
          this.$emit('input', value);
        },
        get() {
          return this.value;
        },
      },
    },

    methods: {
      sendCode() {
        this.code = undefined;
        this.$refs.countdownTimer.resetTimer();
        this.$emit('click:resend');
      },
    },
  };
</script>

<style lang="scss"></style>
