<template lang="pug">
.email-sign-in-form
  v-text-field(
    v-model="email"
    label="Электронная почта"
    hide-details="auto"
    :error-messages="errors.email"
  )
    template(#append)
      ui-icon.c-primary(name="check2")

  v-text-field.mt-4(
    v-model="password"
    type="password"
    label="Пароль"
    autofocus
    hide-details="auto"
    :error-messages="errors.password"
    @keyup.enter="$emit('submit')"
  )
  .t-caption-middle.mt-4
    | Забыли пароль?
    span.c-primary.ml-2(@click="resetPassword") Восстановить пароль

  // попап пароля
  reset-password-dialog(ref="resetPasswordDialog" :email="email")
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';
  import ResetPasswordDialog from './ResetPasswordDialog.vue';
  import { handleError } from '@/libs/handle-error';

  export default {
    components: { UiBtn, UiIcon, ResetPasswordDialog },

    props: {
      email: { type: String, default: undefined },
      value: { type: String, default: undefined },
      errors: { type: Object, default: () => {} },
    },

    computed: {
      password: {
        set(value) {
          this.$emit('input', value);
        },
        get() {
          return this.value;
        },
      },
    },

    watch: {
      email() {
        this.$emit('email', this.email);
      }
    },

    methods: {
      async resetPassword() {
        try {
          await this.$refs.resetPasswordDialog.open();
        } catch (error) {
          handleError(error);
        }
      },
    },
  };
</script>

<style lang="scss"></style>
